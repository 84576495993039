import React, { useState } from 'react';
import '../styles/blogGenerator.css';
import '../styles/gptComponent.css'

function BlogGenerator() {
    const [subject, setSubject] = useState('');
    const [keywords, setKeywords] = useState(['']);
    const [length, setLength] = useState('short');
    const [blog, setBlog] = useState('');
    const [image, setImage] = useState(null);  // New state for DALL·E image URL
    const [loading, setLoading] = useState(false);

    // Function to fetch the DALL·E image based on the blog title
    const fetchDalleImage = async (title) => {
        try {
            const response = await fetch(`https://rebolvertech.vercel.app/api/dalle`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    prompt: title
                })
            });
    
            const data = await response.json();
            setImage(data.imageUrl);
        } catch (error) {
            console.error('Error fetching image from DALL·E:', error);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        await fetchDalleImage(`A realistic and artistic photo of ${subject}.`);
        const prompt = `Write a ${length}-word blog post titled "${subject}." The content should revolve around ${subject} and must incorporate the following keywords within the text: ${keywords.join(', ')}. These keywords should be mixed between sentences and not listed consecutively. Please stick to the topic and provide relevant details.`;
        const blogResponse = await fetchBlogGPTResponse(prompt);
        setBlog(blogResponse);
        setLoading(false);
    };

    const fetchBlogGPTResponse = async () => {
        try {
            const response = await fetch(`https://rebolvertech.vercel.app/api/bloggpt`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    subject: subject,
                    keywords: keywords.join(','),
                    length: length
                })
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Server responded with a ${response.status} status: ${errorData.error}`);
            }
    
            const data = await response.json();
            return data.response || 'No response received.';
        } catch (error) {
            console.error('Error fetching response from BlogGPT:', error);
            return 'Error fetching response from BlogGPT';
        }
    };
    
    const updateKeyword = (index, value) => {
        const updatedKeywords = [...keywords];
        updatedKeywords[index] = value;
        setKeywords(updatedKeywords);
    };

    const addKeywordField = () => {
        setKeywords([...keywords, '']);
    };


return (
    <div className="App">
    <div className="containerGPT">
        <h2>AI SEO Blog Generator</h2>
        
        <label>
            Subject:
            <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
        </label>
        <br />
        <div className="keywords">
        {keywords.map((keyword, index) => (
            <div key={index}>
                <label>
                    Keyword {index + 1}:
                    <input type="text" value={keyword} onChange={(e) => updateKeyword(index, e.target.value)} />
                </label>
                <br />
            </div>
        ))}
        </div>
        <button onClick={addKeywordField}>+ Add Keyword</button>
        <br />
        <label>
            Length:
            <select value={length} onChange={(e) => setLength(e.target.value)}>
                <option value="short">Short</option>
                <option value="medium">Medium</option>
                <option value="large">Large</option>
            </select>
        </label>
        <br />
        <div className={subject && keywords.every(k => k) ? "neon-effect" : ""}>
            <button onClick={handleSubmit} disabled={!subject || !keywords.every(k => k)}>Generate Blog</button>
        </div>
        {loading && <div><img src='https://www.aldenschools.org/cms/lib/NY01813497/Centricity/Domain/201/UnripeAdoredGrayreefshark-max-1mb.gif' alt='Loading...' /><p>Loading...</p></div>}
        <div className='output'>
        {/* Display the generated blog image and text */}
        <div>
        {image && <img src={image} alt="DALL·E generated" />}
        </div>
        <div>
        <p>{blog.text}</p>
        </div>   
        </div>
    </div>
    </div>
);


}

export default BlogGenerator;
