import React from 'react';


const NeonSeparator = () => {
  return (
    <div className="neonSeparator"></div>
  );
};

export default NeonSeparator;
