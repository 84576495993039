import React, { useState, useEffect, lazy } from "react";
import client from "./utils/sanityClient";
import "./styles/intro.css";
import { useTranslation } from "react-i18next";

// Video Component
const Video = ({ videoSrc, altText, maxHeight }) => {
  const fileId = videoSrc.asset._ref.replace("file-", "").split("-")[0];
  const videoUrl = `https://cdn.sanity.io/files/3setjl72/production/${fileId}.${
    videoSrc.asset.extension || "webm"
  }`;

  return (
    <video
      width="100%"
      height={maxHeight}
      autoPlay
      loop
      muted
      aria-label={altText}
      preload="none"
    >
      <source
        src={videoUrl}
        type={`video/${videoSrc.asset.extension || "webm"}`}
      />
      Your browser does not support the video tag.
    </video>
  );
};

// Image Component
const Image = ({ src, alt, maxHeight }) => {
  return (
    <img
      src={src}
      alt={alt}
      style={{ width: "100%", maxHeight: maxHeight || "300px" }}
    />
  );
};

// Module Component
const Module = ({
  title,
  paragraphs,
  videoSrc,
  imgSrc,
  altText,
  CtaText,
  CtaLink,
  index,
  hoveredModule,
  handleModuleHover,
  handleModuleLeave,
  maxHeight,
}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = title && title[i18n.language] ? i18n.language : "en";

  const currentParagraphs =
    paragraphs && paragraphs[currentLanguage]
      ? paragraphs[currentLanguage]
      : [];
  const canPlayWebM = !!document
    .createElement("video")
    .canPlayType('video/webm; codecs="vp8, vorbis"');

  // Construct the URLs for the video and image assets
  const fileId = videoSrc.asset._ref.replace("file-", "").split("-")[0];

  const videoUrl =
    videoSrc &&
    videoSrc.asset &&
    `https://cdn.sanity.io/files/3setjl72/production/${fileId}.${
      videoSrc.asset.extension || "webm"
    }`;
  const imageFileParts = (
    imgSrc && imgSrc.asset._ref.replace("image-", "")
  )?.split("-");
  const imageFileRef = imageFileParts
    ?.slice(0, imageFileParts.length - 1)
    .join("-");
  const imageUrl =
    imgSrc &&
    imgSrc.asset &&
    `https://cdn.sanity.io/images/3setjl72/production/${imageFileRef}.${
      imgSrc.asset.extension || "gif"
    }`;

  const shouldLoadMedia = hoveredModule === index;

  return (
    <div
      className={`contIntro ${shouldLoadMedia ? "hovered" : ""}`}
      onMouseEnter={() => handleModuleHover(index)}
      onMouseLeave={handleModuleLeave}
      style={{ flex: "1", margin: "10px" }}
    >
      {/* Add a label for the module */}
      <h2 id={`moduleTitle_${index}`}>{title[currentLanguage]}</h2>
      {currentParagraphs.map((p, i) => (
        <p key={i}>{p}</p>
      ))}

      <React.Suspense fallback={<div>Loading media...</div>}>
        {videoSrc ? (
          <Video videoSrc={videoSrc} altText={altText} maxHeight={maxHeight} />
        ) : (
          <Image src={imageUrl} alt={altText} maxHeight={maxHeight} />
        )}
      </React.Suspense>

      {/* Use aria-label to provide a more descriptive label */}
      <div className={`neon-effect`}>
        <a href={CtaLink} aria-label={altText}>
          <button className="neonButton">{CtaText[currentLanguage]}</button>
        </a>
      </div>
    </div>
  );
};

const Intro = () => {
  const [hoveredModule, setHoveredModule] = useState(null);
  const [modules, setModules] = useState([]);

  useEffect(() => {
    const fetchModulesFromSanity = async () => {
      const data = await client.fetch('*[_type == "module"]');
      setModules(data);
    };

    fetchModulesFromSanity();
  }, []);

  const handleModuleHover = (index) => {
    setHoveredModule(index);
  };

  const handleModuleLeave = () => {
    setHoveredModule(null);
  };

  return (
    <section className="intro-section">
      {modules.map((module, index) => (
        <Module
          key={index}
          index={index}
          hoveredModule={hoveredModule}
          handleModuleHover={handleModuleHover}
          handleModuleLeave={handleModuleLeave}
          {...module}
        />
      ))}
    </section>
  );
};

export default Intro;
