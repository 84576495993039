import { createClient } from '@sanity/client';

const client = createClient({
  projectId: '3setjl72',
  dataset: 'production',
  apiVersion: '2023-09-01', // use today's date
  useCdn: true,
});

export default client;
