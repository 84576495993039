import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCog, faLink, faTabletAlt, faPaintBrush, faEdit, faShieldAlt, faBroadcastTower,
    faComments, faShoppingCart, faTruck, faPlug, faChartBar, faTools, faDatabase, faRocket
} from '@fortawesome/free-solid-svg-icons';

const EcommerceServices = () => {
    const { t } = useTranslation();
    return (
        <div className="services-section" id='web-design'>
            <h2 className="title">{t('Transforma Tu Presencia Digital')}</h2>
            
            {/* Web Development + Info */}
            <div className="service">
            <div className="conrt">
            <h3 className="service-title">{t('Desarrollo de Sitios Web')}</h3>
    <ul className="service-list">
        <li>{t('Sitio Web de Impacto: Mínimo tiempo entre idea y realización, garantizando que tu espacio en línea esté activo en un instante.')}</li>
        <li>{t('Optimización Móvil: Aseguramos que tu sitio se vea y funcione perfectamente en todos los dispositivos, desde computadoras hasta smartphones.')}</li>
        <li>{t('Integración SEO: Nuestros desarrollos estxán optimizados desde el inicio para buscadores, asegurando una mejor visibilidad en Google y otros motores de búsqueda.')}</li>
        <li>{t('Plataformas Modernas: Utilizamos tecnologías líderes como React y Shopify para ofrecer soluciones flexibles y escalables.')}</li>
    </ul>
</div>
                <div className="illustration">
                    <img src="https://s3.amazonaws.com/www-inside-design/uploads/2015/08/hero-rwd-1-animated.gif" alt='responsivo'/>
                </div>
            </div>

            {/* ¿Qué incluye? for Web Development */}
            <div className="inclusive-section">
            <h3>{t('¿Qué incluye para Desarrollo Web?')}</h3>
    <ul className="inclusive-list">
        <li>
            <FontAwesomeIcon icon={faCog} />
            <div>
                <span className="item-title">{t('Desarrollo Moderno:')}</span><br></br> {t('Especialistas en Desarrollo React, construido y desplegado en Vercel para garantizar velocidad y desempeño óptimo.')}
            </div>
        </li>
        <li>
            <FontAwesomeIcon icon={faTabletAlt} />
            <div>
                <span className="item-title">{t('Diseño Adaptable:')}</span><br></br> {t('Garantizamos un diseño web adaptable que funciona en todos dispositivos y navegadores populares, maximizando la conectividad con tus usuarios.')}
            </div>
        </li>
        <li>
            <FontAwesomeIcon icon={faPaintBrush} />
            <div>
                <span className="item-title">{t('Diseño Único:')}</span><br></br> {t('Siguiendo las tendencias de diseño web y con un enfoque centrado en el usuario, aseguramos que cada proyecto refleje su esencia y promueva conversiones.')}
            </div>
        </li>
        <li>
            <FontAwesomeIcon icon={faEdit} />
            <div>
                <span className="item-title">{t('Contenido a tu Medida:')}</span><br></br> {t('Con un CMS avanzado en Sanity, blog integrado y capacitación, facilitamos la administración y actualización constante de tu contenido.')}
            </div>
        </li>
        <li>
            <FontAwesomeIcon icon={faBroadcastTower} />
            <div>
                <span className="item-title">{t('Dominio y Notificaciones:')}</span><br></br> {t('Te ofrecemos el primer año de dominio gratis y notificaciones personalizadas que reflejan tu marca en cada detalle.')}
            </div>
        </li>
        <li>
            <FontAwesomeIcon icon={faShieldAlt} />
            <div>
                <span className="item-title">{t('Optimización Integral:')}</span><br></br> {t('Aplicamos técnicas avanzadas de SEO y configuración de píxeles y meta tags para que tu sitio esté listo para destacar en buscadores.')}
            </div>
        </li>
        <li>
            <FontAwesomeIcon icon={faTools} />
            <div>
                <span className="item-title">{t('Administración y Seguridad:')}</span><br></br> {t('Ofrecemos un panel administrable, seguridad SSL y soluciones Blockchain para reforzar la autenticidad y confianza en tu sitio.')}
            </div>
        </li>
        <li>
            <FontAwesomeIcon icon={faComments} />
            <div>
                <span className="item-title">{t('Interacción y Comunicación:')}</span><br></br> {t('Integramos chat en vivo para sitios web de tu elección, diseñamos banners personalizados y te conectamos con aliados comerciales para ofrecerte beneficios exclusivos.')}
            </div>
        </li>
        <li>
            <FontAwesomeIcon icon={faPlug} />
            <div>
                <span className="item-title">{t('Integración con Tecnología de Punta:')}</span><br></br> {t('Incorporamos APIs de inteligencia artificial y soluciones Web3 para asegurar que tu sitio esté a la vanguardia digital.')}
            </div>
        </li>
    </ul>

            </div>

            <div id='ecommerce'></div>
            <div className="service" >
            <div className="conrt">
            <h3 className="service-title">{t('Mantenimiento de E-commerce')}</h3>
    <ul className="service-list">
        <li>{t('Más allá de la creación, nos aseguramos de que tu tienda en línea opere sin contratiempos. Desde actualizaciones de inventario hasta promociones, somos tu equipo de respaldo.')}</li>
        <li>{t('Respuesta Rápida: Ante cualquier inconveniente o requerimiento, nuestro equipo está listo para responder y solucionar en tiempo récord.')}</li>
        <li>{t('Mejoras Continuas: No sólo mantenemos tu tienda, también proponemos y ejecutamos mejoras basadas en las últimas tendencias y análisis de datos.')}</li>
        <li>{t('Integraciones Externas: Aseguramos que tu tienda se conecte perfectamente con herramientas externas, desde sistemas de pago hasta CRM y más.')}</li>
    </ul>

</div>
                <div className="illustration">
                    <img src="https://otherwisz.com/wp-content/uploads/2020/05/owblog-ecommerce-blog-graphic-final2.gif" alt='ecommerce' />
                </div>
            </div>

            {/* ¿Qué incluye? for E-commerce */}
            <div className="inclusive-section">
            <h3>{t('¿Qué incluye para E-commerce?')}</h3>
<ul className="inclusive-list">
    <li>
        <FontAwesomeIcon icon={faShoppingCart} />
        <div>
            <span className="item-title">{t('Comercio Electrónico Avanzado')}:</span><br />
            {t('Realizamos el desarrollo personalizado en Shopify con herramientas como catálogo online, soluciones para recuperar carritos abandonados y versátiles motores de pago.')}
        </div>
    </li>
    <li>
        <FontAwesomeIcon icon={faTruck} />
        <div>
            <span className="item-title">{t('Métodos de Envío')}:</span><br />
            {t('Proponemos opciones de envío flexibles adaptadas a tus necesidades y las de tus clientes, desde tarifas fijas hasta soluciones logísticas avanzadas.')}
        </div>
    </li>
    <li>
        <FontAwesomeIcon icon={faPlug} />
        <div>
            <span className="item-title">{t('Integración con Tecnología de Punta')}:</span><br />
            {t('Incorporamos APIs de inteligencia artificial y soluciones Web3 para asegurar que tu sitio esté a la vanguardia digital.')}
        </div>
    </li>
    <li>
        <FontAwesomeIcon icon={faChartBar} />
        <div>
            <span className="item-title">{t('Herramientas de Análisis')}:</span><br />
            {t('Integramos herramientas como Google Analytics 4, Search Console y Tag Manager, fundamentales para un seguimiento detallado y generación de informes.')}
        </div>
    </li>
    <li>
        <FontAwesomeIcon icon={faLink} />
        <div>
            <span className="item-title">{t('Mantenimiento y Soporte')}:</span><br />
            {t('Garantizamos la continuidad con actualizaciones regulares, monitoreo 24/7 y soporte constante para tu sitio.')}
        </div>
    </li>
    <li>
        <FontAwesomeIcon icon={faShieldAlt} />
        <div>
            <span className="item-title">{t('Optimización Integral')}:</span><br />
            {t('Aplicamos técnicas avanzadas de SEO y configuración de píxeles y meta tags para que tu sitio esté listo para destacar en buscadores.')}
        </div>
    </li>
    <li>
        <FontAwesomeIcon icon={faTools} />
        <div>
            <span className="item-title">{t('Administración y Seguridad')}:</span><br />
            {t('Ofrecemos un panel administrable, seguridad SSL y soluciones Blockchain para reforzar la autenticidad y confianza en tu sitio.')}
        </div>
    </li>
    <li>
        <FontAwesomeIcon icon={faDatabase} />
        <div>
            <span className="item-title">{t('Data Mining')}:</span><br />
            {t('Aplicamos técnicas de minería de datos para descubrir patrones y brindar insights valiosos sobre el comportamiento de tus usuarios y las tendencias del negocio.')}
        </div>
    </li>
    <li>
        <FontAwesomeIcon icon={faRocket} />
        <div>
            <span className="item-title">{t('Innovación Continua')}:</span><br />
            {t('Nos mantenemos al día con las últimas tecnologías y tendencias para garantizar que tu sitio web siempre esté a la vanguardia y ofrezca la mejor experiencia a tus usuarios.')}
        </div>
    </li>
</ul>

            </div>

            {/* What Makes Us Different: Minería de Datos & Dashboards Personalizados */}
            <div className="service">
            <div className="conrt">
            <h3 className="service-title">{t('Análisis Predictivo y Business Intelligence: Desbloquea el Poder de Tus Datos')}</h3>
<ul className="service-list">
    <li>{t('Minería de Datos para Marketing: Entiende a tu audiencia como nunca antes. Nuestras técnicas avanzadas de minería de datos ofrecen insights que definen tus estrategias de marketing.')}</li>
    <li>{t('Dashboards Personalizados: Adiós a los informes genéricos. Visualiza métricas que realmente importan, desde ventas hasta comportamientos del cliente.')}</li>
    <li>{t('Machine Learning para Ventas Cruzadas: Con el poder del aprendizaje automático, identificamos patrones y oportunidades para sugerir ventas adicionales de manera efectiva.')}</li>
</ul>

</div>

                <div className="illustration">
                    <img src="https://i.pinimg.com/originals/c1/4d/42/c14d42b12f9f314b66c91076168a28ce.gif" alt='data mining'/>
                </div>
            </div>

        </div>
    );
}

export default EcommerceServices;
