import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import GLOBE from 'vanta/dist/vanta.globe.min.js'; // Import GLOBE instead of NET

const MyVantaComponent = () => {
  const myRef = useRef(null);

  useEffect(() => {
    let vantaEffect = null;
    if (myRef.current) {
      vantaEffect = GLOBE({ // Use GLOBE instead of NET
        el: myRef.current,
        THREE,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00,
        scale: 1.50,

        color: 0x1daa94,
        color2: 0xffffff,
        color3: 0x1daa94,
        color4: 0x1daa94,
  backgroundColor: 0x000000
      });
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    }
  }, [myRef]);

  return (
    <div ref={myRef} className="s-page-1 s-section-1 s-section">
      {/* content here */}
    </div>
  );
};

export default MyVantaComponent;
