import React from 'react';
import Modal from 'react-modal';

const CustomAlert = ({ isOpen, onClose, message, isSuccess }) => {
  const modalStyle = {
    content: {
      width: '300px',
      inset: '5em 73em',
    border: '1px solid rgb(204, 204, 204)',
    background: 'black',
    overflow: 'auto',
    borderRadius: '8px',
    outline: 'none',
    padding: '20px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 4px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignContent: 'center',
    height: '2em'
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={modalStyle}>
      <p style={{ color: isSuccess ? '#1daa94' : 'red' }}>{message}</p>
      <button onClick={onClose} style={{ marginTop: '10px' }}>Close</button>
    </Modal>
  );
};

export default CustomAlert;
