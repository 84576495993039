import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      // HEADER
      "Services": "Services",
      "Our Team": "Our Team",
      "Innovation Zone": "Innovation Zone",
      "Web Design": "Web Design",
      "eCommerce": "eCommerce",
      "Web 3 (Blockchain)": "Web 3 (Blockchain)",
      "Artificial Intelligence": "Artificial Intelligence",
      "Consultants": "Consultants",
      "Our Work": "Our Work",
      "Emerging Tech": "Emerging Tech",
      "Case Applications": "Case Applications",
      "Contact": "Contact",
      // BANNER
      "Rebolver Technology Consulting LLC.": "Rebolver Technology Consulting LLC.",
      "Technology consulting specialized in": "Technology consulting specialized in",
      "ecommerce": "ecommerce",
      "web3": "web3",
      "A.I": "A.I",
      "Get in Touch": "Get in Touch",
      // Intro Component
     "explore_ecommerce": "READ MORE",
      // SERVICE PAGE
      "Web Design & Ecommerce": "Web Design & Ecommerce",
      "Digital Transformation": "Digital Transformation",
      "Whether you're starting fresh or already have a digital presence, we help transform your business to compete in the digital age.": "Whether you're starting fresh or already have a digital presence, we help transform your business to compete in the digital age.",
      "Branding": "Branding",
      "From scratch or a rebrand, we ensure your brand stands out and resonates with your target audience.": "From scratch or a rebrand, we ensure your brand stands out and resonates with your target audience.",
      "End-to-End Ecommerce Solutions": "End-to-End Ecommerce Solutions",
      "We offer comprehensive ecommerce services, from product cataloging and photo shoots to fulfillment logistics.": "We offer comprehensive ecommerce services, from product cataloging and photo shoots to fulfillment logistics.",
      "Digital Marketing": "Digital Marketing",
      "Drive sales and brand awareness with our expert digital marketing strategies.": "Drive sales and brand awareness with our expert digital marketing strategies.",
      "Training & Handover": "Training & Handover",
      "We don't just build and leave. We ensure your internal team is well-equipped to take over, offering training to make the transition seamless.": "We don't just build and leave. We ensure your internal team is well-equipped to take over, offering training to make the transition seamless.",
      "Web3 (Blockchain)": "Web3 (Blockchain)",
      "Smart Contracts": "Smart Contracts",
      "From drafting to deployment, ensure your contracts are efficient, secure, and serve your business needs.": "From drafting to deployment, ensure your contracts are efficient, secure, and serve your business needs.",
      "Blockchain Integration": "Blockchain Integration",
      "Dive into the decentralized world with our expertise in various blockchain technologies.": "Dive into the decentralized world with our expertise in various blockchain technologies.",
      "Consultation": "Consultation",
      "Not sure where to start or how Web3 can benefit your business? Let us guide you.": "Not sure where to start or how Web3 can benefit your business? Let us guide you.",
      "GPT-4 Integration": "GPT-4 Integration",
      "Elevate your business operations, customer service, and more with the power of GPT-4, one of the most advanced AI models.": "Elevate your business operations, customer service, and more with the power of GPT-4, one of the most advanced AI models.",
      "Custom AI Solutions": "Custom AI Solutions",
      "Beyond GPT-4, we can help you integrate various AI tools and solutions tailored to your business needs.": "Beyond GPT-4, we can help you integrate various AI tools and solutions tailored to your business needs.",
      "AI Strategy": "AI Strategy",
      "Ensure you're leveraging AI in the most efficient way, with a clear roadmap and strategy.": "Ensure you're leveraging AI in the most efficient way, with a clear roadmap and strategy.",
      // CONSULTANTS
      'Areas of Specialization': 'Areas of Specialization',
      'Experience': 'Experience',
      'Education and Qualifications': 'Education and Qualifications',
      'Skills': 'Skills',
      'Project Highlights': 'Project Highlights',

      //INNOVATION
      "Hello! This is Chad, your personal AI Tech Consultant from Rebolver, I'm going to guide you through all the cool stuff that we can do at": "Hello! This is Chad, your personal AI Tech Consultant from Rebolver, I'm going to guide you through all the cool stuff that we can do at",
      "Company Name": "Company Name",
      "Company Info": "Company Info",
      "Question": "Question",
      "Select a Question": "Select a Question",
      "How can Rebolver assist me in launching my own ecommerce website?": "How can Rebolver assist me in launching my own ecommerce website?",
      "What strategies does Rebolver recommend for boosting sales or enhancing my website?": "What strategies does Rebolver recommend for boosting sales or enhancing my website?",
      "Can Rebolver guide me in entering the blockchain consulting space?": "Can Rebolver guide me in entering the blockchain consulting space?",
      "How can Rebolver help me integrate AI into my business operations?": "How can Rebolver help me integrate AI into my business operations?",
      "Submit": "Submit",
      "Loading...": "Loading...",
      "No response received.": "No response received.",
      "Error fetching response from ChatGPT": "Error fetching response from ChatGPT",
      "Ask Chad: Your Rebolver AI Guide": "Ask Chad: Your Rebolver AI Guide",
      "Enter your company name": "Enter your company name",
      "Enter some information about your company": "Enter some information about your company",
      "Select an option...": "Select an option...",
      // FORM
      "Full Name": "Full Name",
      "Email": "Email",
      "Company": "Company",
      "Phone": "Phone",
      "Choose the assessment of your interest": "Choose the assessment of your interest",
      "Branding & E-commerce Solutions": "Branding & E-commerce Solutions",
      "Smart Contracts & Blockchain": "Smart Contracts & Blockchain",
      "AI Integration & Strategy": "AI Integration & Strategy",
      "Email sent successfully!": "Email sent successfully!",
      "An error occurred. Please try again later.": "An error occurred. Please try again later.",
      // SERVICES/ECOMMERCE
      "Digitize Your Business": "Digitize Your Business",
      "Get a Quote for Your Website Today with Us": "Get a Quote for Your Website Today with Us",
      "Empowering Your Business Digitally": "Empowering Your Business Digitally",
      "Cutting-edge websites and digital solutions tailored for your success.": "Cutting-edge websites and digital solutions tailored for your success.",
      "Explore Our Services": "Explore Our Services",
      "Transforma Tu Presencia Digital": "Transform Your Digital Presence",
      "Desarrollo de Sitios Web": "Website Development & Design",
"Sitio Web de Impacto: Mínimo tiempo entre idea y realización, garantizando que tu espacio en línea esté activo en un instante.": "High-Impact Website: Minimal time from idea to realization, ensuring your online space is active in an instant.",
"Optimización Móvil: Aseguramos que tu sitio se vea y funcione perfectamente en todos los dispositivos, desde computadoras hasta smartphones.": "Mobile Optimization: We ensure that your site looks and functions perfectly on all devices, from computers to smartphones.",
"Integración SEO: Nuestros desarrollos están optimizados desde el inicio para buscadores, asegurando una mejor visibilidad en Google y otros motores de búsqueda.": "SEO Integration: Our developments are optimized from the start for search engines, ensuring better visibility on Google and other search engines.",
"Plataformas Modernas: Utilizamos tecnologías líderes como React y Shopify para ofrecer soluciones flexibles y escalables.": "Modern Platforms: We use leading technologies like React and Shopify to offer flexible and scalable solutions.",
"¿Qué incluye para Desarrollo Web?": "What's Included in Web Development?",
    "Desarrollo Moderno:": "Modern Development:",
    "Especialistas en Desarrollo React, construido y desplegado en Vercel para garantizar velocidad y desempeño óptimo.": "Experts in React Development, built and deployed on Vercel ensuring optimal speed and performance.",
    "Diseño Adaptable:": "Responsive Design:",
    "Garantizamos un diseño web adaptable que funciona en todos dispositivos y navegadores populares, maximizando la conectividad con tus usuarios.": "We guarantee a responsive web design that works across all popular devices and browsers, maximizing your connectivity with users.",
    "Diseño Único:": "Unique Design:",
    "Siguiendo las tendencias de diseño web y con un enfoque centrado en el usuario, aseguramos que cada proyecto refleje su esencia y promueva conversiones.": "Following web design trends with a user-centric approach, we ensure each project mirrors its essence and promotes conversions.",
    "Contenido a tu Medida:": "Custom Content:",
    "Con un CMS avanzado en Sanity, blog integrado y capacitación, facilitamos la administración y actualización constante de tu contenido.": "With an advanced CMS in Sanity, integrated blog, and training, we simplify content management and regular updates.",
    "Dominio y Notificaciones:": "Domain and Notifications:",
    "Te ofrecemos el primer año de dominio gratis y notificaciones personalizadas que reflejan tu marca en cada detalle.": "We offer the first year of domain for free and personalized notifications that reflect your brand in every detail.",
    "Optimización Integral:": "Comprehensive Optimization:",
    "Aplicamos técnicas avanzadas de SEO y configuración de píxeles y meta tags para que tu sitio esté listo para destacar en buscadores.": "We apply advanced SEO techniques, pixel setup, and meta tag configurations to ensure your site stands out in search engines.",
    "Administración y Seguridad:": "Management and Security:",
    "Ofrecemos un panel administrable, seguridad SSL y soluciones Blockchain para reforzar la autenticidad y confianza en tu sitio.": "We provide a manageable panel, SSL security, and Blockchain solutions to reinforce authenticity and trust on your site.",
    "Interacción y Comunicación:": "Interaction and Communication:",
    "Integramos chat en vivo para sitios web de tu elección, diseñamos banners personalizados y te conectamos con aliados comerciales para ofrecerte beneficios exclusivos.": "We integrate live chat for your chosen websites, design custom banners, and connect you with business partners for exclusive benefits.",
    "Integración con Tecnología de Punta:": "Integration with Cutting-Edge Technology:",
    "Incorporamos APIs de inteligencia artificial y soluciones Web3 para asegurar que tu sitio esté a la vanguardia digital.": "We incorporate AI APIs and Web3 solutions to ensure your site stays at the forefront of digital technology.",
    "Mantenimiento de E-commerce": "E-commerce Maintenance",
"Más allá de la creación, nos aseguramos de que tu tienda en línea opere sin contratiempos. Desde actualizaciones de inventario hasta promociones, somos tu equipo de respaldo.": "Beyond just creation, we ensure your online store operates seamlessly. From inventory updates to promotions, we're your backup team.",
"Respuesta Rápida: Ante cualquier inconveniente o requerimiento, nuestro equipo está listo para responder y solucionar en tiempo récord.": "Quick Response: Should any issues or requirements arise, our team is ready to respond and resolve in record time.",
"Mejoras Continuas: No sólo mantenemos tu tienda, también proponemos y ejecutamos mejoras basadas en las últimas tendencias y análisis de datos.": "Continuous Improvements: We don't just maintain your store; we also propose and implement enhancements based on the latest trends and data analysis.",
"Integraciones Externas: Aseguramos que tu tienda se conecte perfectamente con herramientas externas, desde sistemas de pago hasta CRM y más.": "External Integrations: We ensure your store integrates seamlessly with external tools, from payment systems to CRM and beyond.",
  "¿Qué incluye para E-commerce?": "What's Included for E-commerce?",
  "Comercio Electrónico Avanzado": "Advanced E-commerce",
  "Realizamos el desarrollo personalizado en Shopify con herramientas como catálogo online, soluciones para recuperar carritos abandonados y versátiles motores de pago.": "We offer customized Shopify development with features like online catalogues, solutions for abandoned cart recovery, and versatile payment gateways.",
  "Métodos de Envío": "Shipping Methods",
  "Proponemos opciones de envío flexibles adaptadas a tus necesidades y las de tus clientes, desde tarifas fijas hasta soluciones logísticas avanzadas.": "We offer flexible shipping options tailored to both your needs and your customers', from flat rates to advanced logistical solutions.",
  "Integración con Tecnología de Punta": "Cutting-Edge Technology Integration",
  
  "Herramientas de Análisis": "Analytical Tools",
  "Integramos herramientas como Google Analytics 4, Search Console y Tag Manager, fundamentales para un seguimiento detallado y generación de informes.": "We incorporate tools such as Google Analytics 4, Search Console, and Tag Manager, essential for in-depth tracking and report generation.",
  "Mantenimiento y Soporte": "Maintenance and Support",
  "Garantizamos la continuidad con actualizaciones regulares, monitoreo 24/7 y soporte constante para tu sitio.": "We ensure continuity with regular updates, 24/7 monitoring, and constant support for your site.",
  "Optimización Integral": "Holistic Optimization",

  "Administración y Seguridad": "Administration and Security",
  
  "Data Mining": "Data Mining",
  "Aplicamos técnicas de minería de datos para descubrir patrones y brindar insights valiosos sobre el comportamiento de tus usuarios y las tendencias del negocio.": "We utilize data mining techniques to uncover patterns and provide valuable insights into your users' behavior and business trends.",
  "Innovación Continua": "Continuous Innovation",
  "Nos mantenemos al día con las últimas tecnologías y tendencias para garantizar que tu sitio web siempre esté a la vanguardia y ofrezca la mejor experiencia a tus usuarios.": "We stay updated with the latest technologies and trends to ensure your website remains cutting-edge and offers the best experience to your users.",
  "Análisis Predictivo y Business Intelligence: Desbloquea el Poder de Tus Datos": "Predictive Analysis and Business Intelligence: Unlock the Power of Your Data",
    "Minería de Datos para Marketing: Entiende a tu audiencia como nunca antes. Nuestras técnicas avanzadas de minería de datos ofrecen insights que definen tus estrategias de marketing.": "Data Mining for Marketing: Understand your audience like never before. Our advanced data mining techniques provide insights that shape your marketing strategies.",
    "Dashboards Personalizados: Adiós a los informes genéricos. Visualiza métricas que realmente importan, desde ventas hasta comportamientos del cliente.": "Custom Dashboards: Say goodbye to generic reports. Visualize metrics that truly matter, from sales to customer behaviors.",
    "Machine Learning para Ventas Cruzadas: Con el poder del aprendizaje automático, identificamos patrones y oportunidades para sugerir ventas adicionales de manera efectiva.": "Machine Learning for Cross-Selling: With the power of machine learning, we identify patterns and opportunities to suggest additional sales effectively."


    }
  },
  es: {
    translation: {
      // HEADER
      "Services": "Servicios",
      "Our Team": "Nuestro Equipo",
      "Innovation Zone": "Centro de Innovación",
      "Web Design": "Diseño Web",
      "eCommerce": "Comercio Electrónico",
      "Web 3 (Blockchain)": "Web 3 (Blockchain)",
      "Artificial Intelligence": "Inteligencia Artificial",
      "Consultants": "Consultores",
      "Our Work": "Nuestro Trabajo",
      "Emerging Tech": "Tecnologías Emergentes",
      "Case Applications": "Aplicaciones de Casos",
      "Contact": "Contacto",
      // BANNER
      "Rebolver Technology Consulting LLC.": "Rebolver Technology Consulting LLC.",
      "Technology consulting specialized in": "Consultoría tecnológica especializada en",
      "ecommerce": "comercio electrónico",
      "web3": "web3",
      "A.I": "I.A",
      "Get in Touch": "Contacto",
      // Intro Component
    "explore_ecommerce": "VER MAS",
      // PARTNERS HOME
     "Empowering Innovation Working with top-tier Tech" : "Trabajamos con tecnología de punta",
      // SERVICE PAGE
      "Web Design & Ecommerce": "Diseño Web y Comercio electrónico",
      "Digital Transformation": "Transformación Digital",
      "Whether you're starting fresh or already have a digital presence, we help transform your business to compete in the digital age.": "Ya sea que estés comenzando desde cero o ya tengas presencia digital, ayudamos a transformar tu negocio para competir en la era digital.",
      "Branding": "Branding",
      "From scratch or a rebrand, we ensure your brand stands out and resonates with your target audience.": "Desde cero o un cambio de imagen, nos aseguramos de que tu marca destaque y resuene con tu público objetivo.",
      "End-to-End Ecommerce Solutions": "Soluciones de Comercio Electrónico de Extremo a Extremo",
      "We offer comprehensive ecommerce services, from product cataloging and photo shoots to fulfillment logistics.": "Ofrecemos servicios integrales de comercio electrónico, desde la catalogación de productos y sesiones de fotos hasta la logística de cumplimiento.",
      "Digital Marketing": "Marketing Digital",
      "Drive sales and brand awareness with our expert digital marketing strategies.": "Impulsa las ventas y la conciencia de marca con nuestras estrategias expertas de marketing digital.",
      "Training & Handover": "Formación y Traspaso",
      "We don't just build and leave. We ensure your internal team is well-equipped to take over, offering training to make the transition seamless.": "No solo construimos y nos vamos. Nos aseguramos de que tu equipo interno esté bien equipado para hacerse cargo, ofreciendo capacitación para hacer la transición sin problemas.",
      "Web3 (Blockchain)": "Web3 (Blockchain)",
      "Smart Contracts": "Contratos Inteligentes",
      "From drafting to deployment, ensure your contracts are efficient, secure, and serve your business needs.": "Desde la redacción hasta la implementación, asegúrate de que tus contratos sean eficientes, seguros y satisfagan las necesidades de tu negocio.",
      "Blockchain Integration": "Integración de Blockchain",
      "Dive into the decentralized world with our expertise in various blockchain technologies.": "Sumérgete en el mundo descentralizado con nuestra experiencia en varias tecnologías de blockchain.",
      "Consultation": "Consulta",
      "Not sure where to start or how Web3 can benefit your business? Let us guide you.": "¿No estás seguro de por dónde empezar o cómo Web3 puede beneficiar a tu negocio? Déjanos guiarte.",
      "GPT-4 Integration": "Integración de GPT-4",
      "Elevate your business operations, customer service, and more with the power of GPT-4, one of the most advanced AI models.": "Eleva las operaciones de tu negocio, el servicio al cliente y más con el poder de GPT-4, uno de los modelos de IA más avanzados.",
      "Custom AI Solutions": "Soluciones de IA Personalizadas",
      "Beyond GPT-4, we can help you integrate various AI tools and solutions tailored to your business needs.": "Más allá de GPT-4, podemos ayudarte a integrar varias herramientas y soluciones de IA adaptadas a las necesidades de tu negocio.",
      "AI Strategy": "Estrategia de IA",
      "Ensure you're leveraging AI in the most efficient way, with a clear roadmap and strategy.": "Asegúrate de estar aprovechando la IA de la manera más eficiente, con una hoja de ruta y estrategia claras.",

      "Why Rebolver?": "¿Cuál es la ventaja de Rebolver?",
      "Embracing digitalization means building an integrated, efficient, and customer-centric business model. Let us help you make the transition and thrive in the digital age.": "Adoptar la digitalización significa construir un modelo de negocio integrado, eficiente y centrado en el cliente. Permítenos ayudarte a hacer la transición y prosperar en la era digital.",
      "A strong brand identity is key in the digital realm. Let's shape perceptions and drive customer loyalty together.": "Una identidad de marca fuerte es clave en el ámbito digital. Moldeemos juntos las percepciones e impulsemos la lealtad del cliente.",
      "Benefit from cutting-edge front-end and back-end technologies that ensure a smooth user experience and efficient operations.": "Benefíciate de las tecnologías de vanguardia de front-end y back-end que aseguran una experiencia de usuario fluida y operaciones eficientes.",
      "Beyond just driving traffic, we focus on attracting the right audience and converting leads into loyal customers.": "Más allá de simplemente generar tráfico, nos enfocamos en atraer a la audiencia correcta y convertir los leads en clientes leales.",
      "Ensuring your team is empowered with the knowledge and skills to manage and maintain the digital solutions we provide is paramount to us.": "Para nosotros es fundamental asegurarnos de que tu equipo esté empoderado con el conocimiento y las habilidades para gestionar y mantener las soluciones digitales que proporcionamos.",
      "As pioneers of Web3 technologies in Mexico, we bring nationally recognized expertise to the table, ensuring your decentralized solutions are robust and effective.": "Como pioneros en tecnologías Web3 en México, aportamos una experiencia reconocida a nivel nacional, asegurando que tus soluciones descentralizadas sean robustas y efectivas.",
      "Our team stays updated with the rapid advancements in blockchain, ensuring you get solutions that are both innovative and relevant.": "Nuestro equipo se mantiene actualizado con los rápidos avances en blockchain, asegurando que obtengas soluciones innovadoras y relevantes.",
      "With deep knowledge and experience, we provide insights and guidance tailored to your business needs in the decentralized landscape.": "Con un profundo conocimiento y experiencia, proporcionamos perspectivas y orientación adaptadas a las necesidades de tu negocio en el panorama descentralizado.",
      "In the age of automation and data-driven decisions, AI is essential. We ensure you're not just keeping up but staying ahead with the latest in AI technologies.": "En la era de la automatización y las decisiones basadas en datos, la IA es esencial. Nos aseguramos de que no solo te mantengas al día, sino que te adelantes con lo último en tecnologías de IA.",
      "We work closely with you to understand your challenges and goals, ensuring the AI solutions we provide are a perfect fit.": "Trabajamos estrechamente contigo para entender tus desafíos y objetivos, asegurando que las soluciones de IA que proporcionamos sean perfectas.",
      "With our strategic insights and experience, we help you navigate the complexities of AI integration and maximize its benefits for your business.": "Con nuestras perspectivas estratégicas y experiencia, te ayudamos a navegar por las complejidades de la integración de IA y a maximizar sus beneficios para tu negocio.",
      "Harness the power of algorithms and statistical models to enable your systems to perform tasks without using explicit instructions.": "Aprovecha el poder de algoritmos y modelos estadísticos para permitir que tus sistemas realicen tareas sin utilizar instrucciones explícitas.",
"Our team is well-versed with the nuances of machine learning, ensuring your business leverages its full potential for predictive analytics and more.": "Nuestro equipo está bien versado en los matices del aprendizaje automático, asegurando que tu negocio aproveche su máximo potencial para análisis predictivo y más.",
"Extract meaningful patterns and insights from large sets of data and make data-driven decisions for your business.": "Extrae patrones significativos e insights de grandes conjuntos de datos y toma decisiones basadas en datos para tu negocio.",
"We use advanced data mining techniques to provide you with actionable insights, helping you understand market trends and customer preferences.": "Utilizamos técnicas avanzadas de minería de datos para proporcionarte insights accionables, ayudándote a entender las tendencias del mercado y las preferencias de los clientes.",

      //CONSULTANTS
      
      'Areas of Specialization': 'Áreas de Especialización',
      'Experience': 'Experiencia',
      'Education and Qualifications': 'Educación y Cualificaciones',
      'Skills': 'Habilidades',
      'Project Highlights': 'Proyectos Destacados',
      "Our Consultants": "Nuestros Consultores",
      "Showcasing Our Collaborative Ventures": "Empresas con las que hemos Colaborado",
      // INNOVATION
      "Hello! This is Chad, your personal AI Tech Consultant from Rebolver, I'm going to guide you through all the cool stuff that we can do at": "¡Hola! Soy Chad, tu Consultor Tecnológico de IA personal de Rebolver. Te guiaré a través de todas las cosas geniales que podemos hacer en",
      "Company Name": "Nombre de la Empresa",
      "Company Info": "Información de la Empresa",
      "Question": "Pregunta",
      "Select a Question": "Selecciona una Pregunta",
      "How can Rebolver assist me in launching my own ecommerce website?": "¿Cómo puede Rebolver ayudarme a lanzar mi propio sitio web de comercio electrónico?",
      "What strategies does Rebolver recommend for boosting sales or enhancing my website?": "¿Qué estrategias recomienda Rebolver para aumentar las ventas o mejorar mi sitio web?",
      "Can Rebolver guide me in entering the blockchain consulting space?": "¿Puede Rebolver guiarme para ingresar al espacio de consultoría blockchain?",
      "How can Rebolver help me integrate AI into my business operations?": "¿Cómo puede Rebolver ayudarme a integrar la IA en mis operaciones comerciales?",
      "Submit": "Enviar",
      "Loading...": "Cargando...",
      "No response received.": "No se recibió respuesta.",
      "Error fetching response from ChatGPT": "Error al obtener respuesta de ChatGPT",
      "Ask Chad: Your Rebolver AI Guide": "Pregunta a Chad: Tu Guía de IA de Rebolver",
      "Enter your company name": "Nombre de tu empresa",
      "Enter some information about your company": "Cuentanos un poco sobre tu empresa",
      "Select an option...": "Selecciona una opción...",
      //FORM
      "Full Name": "Nombre completo",
      "Email": "Correo electrónico",
      "Company": "Empresa",
      "Phone": "Teléfono",
      "Choose the assessment of your interest": "Elige la evaluación de tu interés",
      "Branding & E-commerce Solutions": "Branding y Soluciones de Comercio Electrónico",
      "Smart Contracts & Blockchain": "Contratos Inteligentes y Blockchain",
      "AI Integration & Strategy": "Integración y Estrategia de IA",
      "Email sent successfully!": "¡Correo electrónico enviado exitosamente!",
      "An error occurred. Please try again later.": "Ocurrió un error. Por favor, inténtalo nuevamente más tarde.",
      // SERVICES/ECOMMERCE
      "Digitize Your Business": "Digitaliza tu Negocio",
      "Get a Quote for Your Website Today with Us": "Cotiza tu Sitio Web Hoy con Nosotros",
      "Empowering Your Business Digitally": "Potenciando Tu Negocio en el Mundo Digital",
"Cutting-edge websites and digital solutions tailored for your success.": "Sitios web innovadores y soluciones digitales diseñadas para tu éxito.",
"Explore Our Services": "Descubre Nuestros Servicios",
"Transforma Tu Presencia Digital": "Transforma Tu Presencia Digital",
"Sitio Web de Impacto: Mínimo tiempo entre idea y realización, garantizando que tu espacio en línea esté activo en un instante.": "Sitio Web de Impacto: Mínimo tiempo entre idea y realización, garantizando que tu espacio en línea esté activo en un instante.",
"Optimización Móvil: Aseguramos que tu sitio se vea y funcione perfectamente en todos los dispositivos, desde computadoras hasta smartphones.":"Optimización Móvil: Aseguramos que tu sitio se vea y funcione perfectamente en todos los dispositivos, desde computadoras hasta smartphones.",
"Integración SEO: Nuestros desarrollos están optimizados desde el inicio para buscadores, asegurando una mejor visibilidad en Google y otros motores de búsqueda.": "Integración SEO: Nuestros desarrollos están optimizados desde el inicio para buscadores, asegurando una mejor visibilidad en Google y otros motores de búsqueda.",
"Plataformas Modernas: Utilizamos tecnologías líderes como React y Shopify para ofrecer soluciones flexibles y escalables.":"Plataformas Modernas: Utilizamos tecnologías líderes como React y Shopify para ofrecer soluciones flexibles y escalables.",
"¿Qué incluye para Desarrollo Web?": "¿Qué incluye para Desarrollo Web?",
    "Desarrollo Moderno:": "Desarrollo Moderno:",
    "Especialistas en Desarrollo React, construido y desplegado en Vercel para garantizar velocidad y desempeño óptimo.": "Especialistas en Desarrollo React, construido y desplegado en Vercel para garantizar velocidad y desempeño óptimo.",
    "Diseño Adaptable:": "Diseño Adaptable:",
    "Garantizamos un diseño web adaptable que funciona en todos dispositivos y navegadores populares, maximizando la conectividad con tus usuarios.": "Garantizamos un diseño web adaptable que funciona en todos dispositivos y navegadores populares, maximizando la conectividad con tus usuarios.",
    "Diseño Único:": "Diseño Único:",
    "Siguiendo las tendencias de diseño web y con un enfoque centrado en el usuario, aseguramos que cada proyecto refleje su esencia y promueva conversiones.": "Siguiendo las tendencias de diseño web y con un enfoque centrado en el usuario, aseguramos que cada proyecto refleje su esencia y promueva conversiones.",
    "Contenido a tu Medida:": "Contenido a tu Medida:",
    "Con un CMS avanzado en Sanity, blog integrado y capacitación, facilitamos la administración y actualización constante de tu contenido.": "Con un CMS avanzado en Sanity, blog integrado y capacitación, facilitamos la administración y actualización constante de tu contenido.",
    "Dominio y Notificaciones:": "Dominio y Notificaciones:",
    "Te ofrecemos el primer año de dominio gratis y notificaciones personalizadas que reflejan tu marca en cada detalle.": "Te ofrecemos el primer año de dominio gratis y notificaciones personalizadas que reflejan tu marca en cada detalle.",
    "Optimización Integral:": "Optimización Integral:",
    "Aplicamos técnicas avanzadas de SEO y configuración de píxeles y meta tags para que tu sitio esté listo para destacar en buscadores.": "Aplicamos técnicas avanzadas de SEO y configuración de píxeles y meta tags para que tu sitio esté listo para destacar en buscadores.",
    "Administración y Seguridad:": "Administración y Seguridad:",
    "Ofrecemos un panel administrable, seguridad SSL y soluciones Blockchain para reforzar la autenticidad y confianza en tu sitio.": "Ofrecemos un panel administrable, seguridad SSL y soluciones Blockchain para reforzar la autenticidad y confianza en tu sitio.",
    "Interacción y Comunicación:": "Interacción y Comunicación:",
    "Integramos chat en vivo para sitios web de tu elección, diseñamos banners personalizados y te conectamos con aliados comerciales para ofrecerte beneficios exclusivos.": "Integramos chat en vivo para sitios web de tu elección, diseñamos banners personalizados y te conectamos con aliados comerciales para ofrecerte beneficios exclusivos.",
    "Integración con Tecnología de Punta:": "Integración con Tecnología de Punta:",
    "Incorporamos APIs de inteligencia artificial y soluciones Web3 para asegurar que tu sitio esté a la vanguardia digital.": "Incorporamos APIs de inteligencia artificial y soluciones Web3 para asegurar que tu sitio esté a la vanguardia digital.",
    "Mantenimiento de E-commerce": "Mantenimiento de E-commerce",
"Más allá de la creación, nos aseguramos de que tu tienda en línea opere sin contratiempos. Desde actualizaciones de inventario hasta promociones, somos tu equipo de respaldo.": "Más allá de la creación, nos aseguramos de que tu tienda en línea opere sin contratiempos. Desde actualizaciones de inventario hasta promociones, somos tu equipo de respaldo.",
"Respuesta Rápida: Ante cualquier inconveniente o requerimiento, nuestro equipo está listo para responder y solucionar en tiempo récord.": "Respuesta Rápida: Ante cualquier inconveniente o requerimiento, nuestro equipo está listo para responder y solucionar en tiempo récord.",
"Mejoras Continuas: No sólo mantenemos tu tienda, también proponemos y ejecutamos mejoras basadas en las últimas tendencias y análisis de datos.": "Mejoras Continuas: No sólo mantenemos tu tienda, también proponemos y ejecutamos mejoras basadas en las últimas tendencias y análisis de datos.",
"Integraciones Externas: Aseguramos que tu tienda se conecte perfectamente con herramientas externas, desde sistemas de pago hasta CRM y más.": "Integraciones Externas: Aseguramos que tu tienda se conecte perfectamente con herramientas externas, desde sistemas de pago hasta CRM y más.",
"¿Qué incluye para E-commerce?": "¿Qué incluye para E-commerce?",
"Comercio Electrónico Avanzado": "Comercio Electrónico Avanzado",
"Realizamos el desarrollo personalizado en Shopify con herramientas como catálogo online, soluciones para recuperar carritos abandonados y versátiles motores de pago.": "Realizamos el desarrollo personalizado en Shopify con herramientas como catálogo online, soluciones para recuperar carritos abandonados y versátiles motores de pago.",
"Métodos de Envío": "Métodos de Envío",
"Proponemos opciones de envío flexibles adaptadas a tus necesidades y las de tus clientes, desde tarifas fijas hasta soluciones logísticas avanzadas.": "Proponemos opciones de envío flexibles adaptadas a tus necesidades y las de tus clientes, desde tarifas fijas hasta soluciones logísticas avanzadas.",
"Integración con Tecnología de Punta": "Integración con Tecnología de Punta",

"Herramientas de Análisis": "Herramientas de Análisis",
"Integramos herramientas como Google Analytics 4, Search Console y Tag Manager, fundamentales para un seguimiento detallado y generación de informes.": "Integramos herramientas como Google Analytics 4, Search Console y Tag Manager, fundamentales para un seguimiento detallado y generación de informes.",
"Mantenimiento y Soporte": "Mantenimiento y Soporte",
"Garantizamos la continuidad con actualizaciones regulares, monitoreo 24/7 y soporte constante para tu sitio.": "Garantizamos la continuidad con actualizaciones regulares, monitoreo 24/7 y soporte constante para tu sitio.",
"Optimización Integral": "Optimización Integral",

"Administración y Seguridad": "Administración y Seguridad",

"Data Mining": "Data Mining",
"Aplicamos técnicas de minería de datos para descubrir patrones y brindar insights valiosos sobre el comportamiento de tus usuarios y las tendencias del negocio.": "Aplicamos técnicas de minería de datos para descubrir patrones y brindar insights valiosos sobre el comportamiento de tus usuarios y las tendencias del negocio.",
"Innovación Continua": "Innovación Continua",
"Nos mantenemos al día con las últimas tecnologías y tendencias para garantizar que tu sitio web siempre esté a la vanguardia y ofrezca la mejor experiencia a tus usuarios.": "Nos mantenemos al día con las últimas tecnologías y tendencias para garantizar que tu sitio web siempre esté a la vanguardia y ofrezca la mejor experiencia a tus usuarios.",
"Análisis Predictivo y Business Intelligence: Desbloquea el Poder de Tus Datos": "Análisis Predictivo y Business Intelligence: Desbloquea el Poder de Tus Datos",
    "Minería de Datos para Marketing: Entiende a tu audiencia como nunca antes. Nuestras técnicas avanzadas de minería de datos ofrecen insights que definen tus estrategias de marketing.": "Minería de Datos para Marketing: Entiende a tu audiencia como nunca antes. Nuestras técnicas avanzadas de minería de datos ofrecen insights que definen tus estrategias de marketing.",
    "Dashboards Personalizados: Adiós a los informes genéricos. Visualiza métricas que realmente importan, desde ventas hasta comportamientos del cliente.": "Dashboards Personalizados: Adiós a los informes genéricos. Visualiza métricas que realmente importan, desde ventas hasta comportamientos del cliente.",
    "Machine Learning para Ventas Cruzadas: Con el poder del aprendizaje automático, identificamos patrones y oportunidades para sugerir ventas adicionales de manera efectiva.": "Machine Learning para Ventas Cruzadas: Con el poder del aprendizaje automático, identificamos patrones y oportunidades para sugerir ventas adicionales de manera efectiva."

    }
  },
};


i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
