import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import CustomAlert from './alert';
import { useTranslation } from 'react-i18next';

const ContactUs = () => {
  const { t } = useTranslation();
  const form = useRef();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_01h72ff', 'template_xy2c4v2', form.current, 'kJ_YwHwwG8_bnlFGb')
      .then((result) => {
        console.log(result.text);
        setShowAlert(true);
        setAlertMessage(t('Email sent successfully!'));
        setIsSuccess(true);
      })
      .catch((error) => {
        console.log(error.text);
        setShowAlert(true);
        setAlertMessage(t('An error occurred. Please try again later.'));
        setIsSuccess(false);
      });
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  return (
    <form ref={form} onSubmit={sendEmail} style={{ width: '400px', margin: '20px' }}>
      <label htmlFor="user_name" style={{ display: 'block', marginBottom: '5px' }}>{t('Full Name')}</label>
      <input type="text" id="user_name" name="user_name" style={{ width: '90%', padding: '10px', marginBottom: '15px' }} />
      <label htmlFor="user_email" style={{ display: 'block', marginBottom: '5px' }}>{t('Email')}</label>
      <input type="email" id="user_email" name="user_email" style={{ width: '90%', padding: '10px', marginBottom: '15px' }} />
      <label htmlFor="empresa" style={{ display: 'block', marginBottom: '5px' }}>{t('Company')}</label>
      <input type="text" id="empresa" name="empresa" style={{ width: '90%', padding: '10px', marginBottom: '15px' }} />
      <label htmlFor="telefono" style={{ display: 'block', marginBottom: '5px' }}>{t('Phone')}</label>
      <input type="tel" id="telefono" name="telefono" style={{ width: '90%', padding: '10px', marginBottom: '15px' }} />
      <label htmlFor="assessment" style={{ display: 'block', marginBottom: '5px' }}>{t('Choose the assessment of your interest')}</label>
      <select id="assessment" name="assessment" style={{ width: '95%', padding: '10px', marginBottom: '15px' }}>
        <option value=""></option>
        <option>{t('Digital Transformation')}</option>
        <option>{t('Branding & E-commerce Solutions')}</option>
        <option>{t('Smart Contracts & Blockchain')}</option>
        <option>{t('AI Integration & Strategy')}</option>
      </select>
      <label htmlFor="message" style={{ display: 'block', marginBottom: '5px' }}>{t('Message')}</label>
      <textarea id="message" name="message" style={{ width: '90%', padding: '10px', marginBottom: '15px' }}></textarea>
      <input className="" type="submit" value={t('Submit')} style={{ padding: '10px 20px', background: '#1daa94', border: 'none', color: '#fff', borderRadius: '6px', fontWeight: 'bold' }} />
      <CustomAlert isOpen={showAlert} onClose={closeAlert} message={alertMessage} isSuccess={isSuccess} />
    </form>
  );
  
};

export default ContactUs;
