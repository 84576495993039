// AuthForm.js

import React, { useState } from 'react';
import { useAuth } from './authContext'; 

function AuthForm() {
  const { register, login, currentUser } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
    } catch (error) {
      console.error("Failed to log in:", error);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      await register(email, password);
    } catch (error) {
      console.error("Failed to register:", error);
    }
  };

  if (currentUser) return null; // If the user is logged in, don't show the auth form.

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    maxWidth: '300px',
    margin: '0 auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '5px'
  };

  const inputStyle = {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ccc'
  };

  const buttonStyle = {
    padding: '10px 15px',
    fontSize: '16px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#007BFF',
    color: 'white',
    cursor: 'pointer'
  };

  return (
    <div className="App">
    <div style={formStyle}>
      <form onSubmit={handleLogin}>
        <input style={inputStyle} type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
        <input style={inputStyle} type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
        <button style={buttonStyle} type="submit">Login</button>
      </form>
      <button style={buttonStyle} onClick={handleRegister}>Register</button>
    </div>
    </div>
  );
}

export default AuthForm;
