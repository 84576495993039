import React, { useState } from 'react';
import '../styles/footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faBehance } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import Form from './Form';

const Modal = ({ onClose, children }) => (
  <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#00000054', padding: '20px', zIndex: 1000, borderRadius: '15px' }}>
    <button className='close-button' onClick={onClose}>X</button>
    {children}
  </div>
);
const Footer = () => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <div>
            <div className="footer">
                <div className="logo-section">
                    <img src="/logoCroppedW.png" alt="Logo" width="150" height="150" loading="lazy" />
                </div>
                <div className="navigation-section desktop">
                    <a href="/services">{t('Services')}</a>
                    <a href="/services/Ecommerce">{t('Ecommerce')}</a>
                    <a href="/consultants">{t('Our Team')}</a>
                    <a href="/innovation-zone">{t('Innovation Zone')}</a>
                </div>
                <div className="navigation-section mobile">
                    <div>
                        <a href="/services">{t('Services')}</a>
                        <ul>
                            <li>
                                <a href="/services/Ecommerce">{t('Ecommerce')}</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <a href="/consultants">{t('Our Team')}</a>
                    </div>
                    <div>
                        <a href="/innovation-zone">{t('Innovation Zone')}</a>
                    </div>
                </div>
                <div className="social-media-section">
                    <div className='butCta'>
                        <button 
                          style={{ padding: '10px 20px', background: '#1daa94', border: 'none', color: '#fff', borderRadius: '6px', fontWeight: 'bold', marginBottom: '1em' }} 
                          onClick={() => setIsModalOpen(true)}
                        >
                          {t('Contact')}
                        </button>
                    </div>
                    <a href="mailto:rodrigo@rebolvertech.com" target="_blank" rel="noopener noreferrer" aria-label="Send an email to Rodrigo">
                        <FontAwesomeIcon icon={faEnvelope} />
                    </a>
                    <a href="https://www.linkedin.com/company/rebolver-tech" target="_blank" rel="noopener noreferrer" aria-label="Rebolver Tech on LinkedIn">
                        <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                    <a href="https://facebook.com/rebolver.consulting" target="_blank" rel="noopener noreferrer" aria-label="Rebolver Consulting on Facebook">
                        <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                    <a href="https://www.behance.net/rbolivarv89ed" target="_blank" rel="noopener noreferrer" aria-label="Rebolver on Behance">
  <FontAwesomeIcon icon={faBehance} />
</a>

                </div>
            </div>
            <div>
                <address>
                    Copyright © 2023 REBOLVER TECH - Elevate Your Business Potential with <a href="https://rebolvertech.com/" target="blank">Rebolver Technology Consulting</a>.
                </address>
            </div>
            {isModalOpen && (
                <Modal onClose={() => setIsModalOpen(false)}>
                    <Form />
                </Modal>
            )}
        </div>
    );
}

export default Footer;