import React, { useState, useEffect } from "react";
import client from "./utils/sanityClient";
import "./index.css";
import { useTranslation } from "react-i18next";
import "./styles/ecommerce.css";
import BannerEcom from "./components/BannerEcom";
import EcommerceServices from "./components/EcommerceServices";
import ContactUs from "./components/Form";
import { Helmet } from "react-helmet";

const PriceTable = ({
  titleEn,
  titleEs,
  imgSrc,
  videoSrc,
  altText,
  packages,
}) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language.startsWith("es") ? "es" : "en";

  const baseHref = "https://rebolvertech.com";
  const pageHref = `${baseHref}/services/ecommerce`;

  const canPlayWebM = !!document
    .createElement("video")
    .canPlayType('video/webm; codecs="vp8, vorbis"');

  // Construct the URLs for the video and image assets
  const fileId =
    videoSrc && videoSrc.asset
      ? videoSrc.asset._ref.replace("file-", "").split("-")[0]
      : null;
  const videoUrl = fileId
    ? `https://cdn.sanity.io/files/3setjl72/production/${fileId}.${
        videoSrc.asset.extension || "webm"
      }`
    : null;

  const imageFileParts = (
    imgSrc && imgSrc.asset._ref.replace("image-", "")
  )?.split("-");
  const imageFileRef = imageFileParts
    ?.slice(0, imageFileParts.length - 1)
    .join("-");
  const imageUrl = imageFileRef
    ? `https://cdn.sanity.io/images/3setjl72/production/${imageFileRef}.${
        imgSrc.asset.extension || "gif"
      }`
    : null;

  const [showForm, setShowForm] = useState(false);

  // Function to toggle the visibility of the ContactUs form
  const handleCotizarClick = (e) => {
    e.preventDefault();
    setShowForm(!showForm);
  };

  return (
    <div className="custom-module-container">
<Helmet>
  <meta charSet="utf-8" />
  <title>
    Páginas Web Ecommerce y Soluciones de Data Mining - Rebolver Technology Consulting
  </title>
  <meta
    name="description"
    content="Explora nuestras soluciones de comercio electrónico y tienda online para transformar tu negocio digitalmente. En Rebolver Technology Consulting, especializamos en creación de páginas web y comercio electrónico efectivo."
  />
  <meta
    name="keywords"
    content="ecommerce, transformación digital, creación de sitios web, integración ecommerce, minería de datos, consultoría de datos, soluciones digitales, tienda en línea, plataforma de venta en línea, optimización de ecommerce, México, diseño web, ventas en línea, comercio electronico, tienda online, diseño de paginas web, que es e learning, rebolver tech, revolver tech"
  />
  {/* Define alternate language versions */}
  <link rel="alternate" hreflang="en" href={`${baseHref}/en/services/ecommerce`} />
  <link rel="alternate" hreflang="es" href={`${baseHref}/es/services/ecommerce`} />
  <link rel="alternate" hreflang="es-MX" href={`${baseHref}/es-mx/services/ecommerce`} />
  
  {/* Update the canonical link to the current page */}
  <link rel="canonical" href={`${baseHref}/services/ecommerce`} />

  {/* Open Graph tags */}
  <meta property="og:title" content="Ecommerce Services, Website Creation & Data Mining - Rebolver Technology Consulting" />
  <meta property="og:description" content="Elevate your business to the next level with our ecommerce and digital transformation solutions. From creating your online store to optimization with data mining, we've got you covered." />
  <meta property="og:image" content="https://rebolvertech.com/logoCroppedW.png" />
  <meta property="og:url" content={`${baseHref}/services/ecommerce`} />
  <meta property="og:type" content="website" />
  <meta property="og:locale" content="es_MX" />

  {/* Twitter Card tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Ecommerce Web Pages & Data Mining Solutions - Rebolver Technology Consulting" />
  <meta name="twitter:description" content="Explore our ecommerce solutions and online store services to transform your business digitally. Specializing in web page creation and effective ecommerce, Rebolver Technology Consulting is your partner in digital success." />
  <meta name="twitter:image" content="https://rebolvertech.com/logoCroppedW.png" />
</Helmet>


      <div>
        <h4>{currentLanguage === "es" ? titleEs : titleEn}</h4>
        {packages && (
          <ul className="custom-features-list">
            {packages.map((packageItem, index) => (
              <li key={index}>
                {currentLanguage === "es"
                  ? packageItem.packageNameEs
                  : packageItem.packageNameEn}
                {packageItem.features && (
                  <ul>
                    {packageItem.features.map((feature, featureIndex) => (
                      <li key={featureIndex}>
                        {currentLanguage === "es"
                          ? feature.featureEs
                          : feature.featureEn}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div>
        {canPlayWebM ? (
          <video
            width="100%"
            autoPlay
            loop
            muted
            aria-label={altText}
            preload="none"
          >
            <source
              src={videoUrl}
              type={`video/${videoSrc.asset.extension || "webm"}`}
            />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img src={imageUrl} alt={altText} style={{ width: "100%" }} />
        )}
      </div>
      <div className="neon-effect">
        {/* Update the anchor tag to handle the click event */}
        <a
          href="#"
          onClick={handleCotizarClick}
          target="_blank"
          rel="noopener noreferrer"
          className="cv-download"
        >
          Cotizar
        </a>
      </div>

      {/* Conditionally render the ContactUs form */}
      {showForm && <ContactUs />}
    </div>
  );
};

const Ecommerce = () => {
  const [ecommerceData, setEcommerceData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchEcommerceDataFromSanity = async () => {
      const data = await client.fetch(
        '*[_type == "ecommerce"] | order(_createdAt asc)'
      );
      setEcommerceData(data);
    };

    fetchEcommerceDataFromSanity();
  }, []);

  const [pageTitle, setPageTitle] = useState("");

  useEffect(() => {
    const updatePageTitle = async () => {
      const titleElement = document.querySelector("title");
      if (titleElement) {
        setPageTitle(titleElement.innerText);
      }
    };

    updatePageTitle();

    document.addEventListener("DOMSubtreeModified", updatePageTitle);

    return () => {
      document.removeEventListener("DOMSubtreeModified", updatePageTitle);
    };
  }, []);

  return (
    <section className="Ecommerce-section">
      <div className="App">
        <div>
          <BannerEcom />
        </div>
        <div>
          <EcommerceServices />
        </div>
        <div id="cotiza"></div>
        <h1 id="cotiza" className="ecTitile">
          <span>{t("Digitize Your Business")}</span>
          {t("Get a Quote for Your Website Today with Us")}
        </h1>
        <div className="flex">
          {ecommerceData.map((dataItem, index) => (
            <div
              key={index}
              className={`ecommerce-card`}
              style={{ flex: "1", margin: "10px" }}
            >
              <PriceTable {...dataItem} />
            </div>
          ))}
        </div>
      </div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>
    </section>
  );
};

export default Ecommerce;
