import React from 'react';
import { useTranslation } from 'react-i18next';

const BannerEcom = () => {
  const { t } = useTranslation();

  return (
    <div className="BannerEcom-container">


      <div className="BannerEcom-content">
        <h2>{t('Empowering Your Business Digitally')}</h2>
        <h3>
          {t('Cutting-edge websites and digital solutions tailored for your success.')}{' '}
        </h3>
        <div>
          <a href='#cotiza' className="neonButton">{t('Explore Our Services')}</a>
        </div>
      </div>

      <div id="animationWrapper" translate="no">
            <svg viewBox="-500 -500 1000 1000">
              <circle r="450"></circle>
              <circle className="equator" r="450"></circle>
              <circle className="meridian" r="450" data-start="165deg" data-end="180deg"></circle>
              <circle className="meridian" r="450" style={{'--start': '150deg', '--end': '165deg'}}></circle>
            <circle className="meridian" r="450" style={{'--start': '135deg', '--end': '150deg'}}></circle>
            <circle className="meridian" r="450" style={{'--start': '120deg', '--end': '135deg'}}></circle>
            <circle className="meridian" r="450" style={{'--start': '105deg', '--end': '120deg'}}></circle>
            <circle className="meridian" r="450" style={{'--start': '90deg', '--end': '105deg'}}></circle>
            <circle className="meridian" r="450" style={{'--start': '75deg', '--end': '90deg'}}></circle>
            <circle className="meridian" r="450" style={{'--start': '60deg', '--end': '75deg'}}></circle>
            <circle className="meridian" r="450" style={{'--start': '45deg', '--end': '60deg'}}></circle>
            <circle className="meridian" r="450" style={{'--start': '30deg', '--end': '45deg'}}></circle>
            <circle className="meridian" r="450" style={{'--start': '15deg', '--end': '30deg'}}></circle>
            <circle className="meridian" r="450" style={{'--start': '0deg', '--end': '15deg'}}></circle>
            </svg>


        </div>

    </div>
  );
};

// Using React's memo to potentially avoid unnecessary re-renders
export default (BannerEcom);
