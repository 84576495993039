import React, { useState, useEffect, memo, Suspense, useCallback } from 'react';
import MyVantaComponent from './Vanta';
import { useTranslation } from 'react-i18next';

const words = ['ecommerce', 'web3', 'A.I', 'SEO', 'big data' ];

const Form = React.lazy(() => import('../src/components/Form'));

const Banner = () => {
  const [showForm, setShowForm] = useState(false);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [animateWord, setAnimateWord] = useState(true);
  const { t } = useTranslation();

  // useCallback to memoize changeWord function
  const changeWord = useCallback(() => {
    setAnimateWord(false);
    const timeout = setTimeout(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      setAnimateWord(true);
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const interval = setInterval(changeWord, 2000);
    return () => clearInterval(interval);
  }, [changeWord]);

  // Using useCallback to memoize handleClick
  const handleClick = useCallback(() => {
    setShowForm(true);
  }, []);

  return (
    <div className="banner-container">
      <MyVantaComponent />
      <div className="banner-content">
        <h2>{t('Rebolver Technology Consulting LLC.')}</h2>
        <h1>
          {t('Technology consulting specialized in')}{' '}
          <span className={`word-change ${animateWord ? 'word-visible' : ''}`}>
            {t(words[currentWordIndex])}
          </span>
          .
        </h1>
        <div
          onClick={handleClick}
          className={`neon-effect ${showForm ? 'button-hidden' : ''}`}
        >
          <button onClick={handleClick} className="neonButton">{t('Get in Touch')}</button>
        </div>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <div className="inBannerForm">
          {showForm && <div className={`banner-form ${showForm ? 'visible' : ''}`}><Form /></div>}
        </div>
      </Suspense>
    </div>
  );
};

export default memo(Banner);
