import React, { useEffect, useState, lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Banner from './Banner';
import Header from './components/Nav';
import Intro from './Intro';
import NeonSeparator from './components/neonSep';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import PartnersCarousel from './components/PartnerCarousel';
import Footer from './components/Footer';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga'; 
import { fetchPartners } from './utils/fetchData';
import BlogGenerator from './components/BlogGenerator';
import AuthForm from './components/AuthForm';
import { useAuth, AuthProvider } from './components/authContext.js'; 
import Ecommerce from './Ecommerce';
import { Helmet } from 'react-helmet';  // <-- Import Helmet here
import EcommerceServices from './components/EcommerceServices.js';

const ConsultantPage = lazy(() => import('./Consultants'));
const ServicePage = lazy(() => import('./ServicePage'));
const InnovationPage = lazy(() => import('./InnovationPage'));


ReactGA.initialize('G-L0ED2BD4PP');

function ProtectedRoute({ children }) {
  const { currentUser } = useAuth();

  return (
    currentUser ? children : <AuthForm />
  );
}

const App = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const baseHref = "https://rebolvertech.com";
  const [sanityPartners, setSanityPartners] = useState([]);

  useEffect(() => {
    const loadPartners = async () => {
      const partnersFromSanity = await fetchPartners();
      // console.log("Fetched Partners:", partnersFromSanity);
      setSanityPartners(partnersFromSanity);
    };
    loadPartners();
  }, []);

  const partners = sanityPartners;

  return (
    <Suspense fallback={<div>Loading...</div>}>
              <Helmet>
  <meta charSet="utf-8" />
  <title>Rebolver Technology Consulting - Innovación y Tecnología en México</title>
  <meta name="description" content="Especialistas en comercio electrónico, diseño de páginas web, y data mining. Rebolver Technology Consulting transforma negocios con tecnología avanzada." />
  <meta name="keywords" content="ecommerce, transformación digital, creación de sitios web, integración ecommerce, minería de datos, consultoría de datos, diseño web, ventas en línea, comercio electronico, tienda online, México, diseño de paginas web, que es e learning, rebolver tech, revolver tech" />
  <meta name="author" content="Rebolver Technology Consulting" />
  <meta name="copyright" content="Rebolver Technology Consulting" />
  <meta name="robots" content="index,follow" />
  <link rel="canonical" href={baseHref} />
  <meta property="og:title" content="Rebolver Technology Consulting - Avanzando en Comercio Electrónico y Data Mining" />
  <meta property="og:description" content="Potencia tu negocio con nuestras soluciones innovadoras en comercio electrónico, data mining y tecnología web3. Rebolver Technology Consulting - Líderes en innovación digital en México." />
  <meta property="og:image" content="https://rebolvertech.com/logoCroppedW.png" />
  <meta property="og:url" content="https://rebolvertech.com" />
  <meta property="og:type" content="website" />
  <meta property="og:locale" content="es_MX" />
  <meta name="twitter:card" content="summary_large_image" />
  { /* English version */
    currentLang === 'en' && (
      <link rel="alternate" hreflang="en" href={`${baseHref}`} />
    )
  }
  { /* Spanish version */
    currentLang === 'es' && (
      <link rel="alternate" hreflang="es" href={`${baseHref}/es`} />
    )
  }
  { /* Mexican Spanish version */
    currentLang === 'es-MX' && (
      <link rel="alternate" hreflang="es-MX" href={`${baseHref}/es-mx`} />
    )
  }
</Helmet>

      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={
            <>
              <Banner />
              <Intro />
              <PartnersCarousel 
                  partnersData={partners} 
                  headerText={t("Empowering Innovation Working with top-tier Tech")}
              />
              <div className='maxW'>
              <EcommerceServices />
              </div>

            </>
          }/>
          <Route path="/consultants" element={<ConsultantPage />} />
          <Route path="/services" element={<ServicePage />} />
          <Route path="/services/ecommerce" element={<Ecommerce />} />
          <Route path="/innovation-zone" element={<InnovationPage />} />
          <Route path="/blog-generator" element={
            <ProtectedRoute>
              <BlogGenerator />
            </ProtectedRoute>
          } />
        </Routes>
        <NeonSeparator />
        <Footer />
      </Router>
    </Suspense>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <App />
  </AuthProvider>
);

reportWebVitals();
