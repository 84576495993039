import sanityClient from './sanityClient';

export const fetchPartners = async () => {
  const query = '*[_type == "partner"]';
  const partners = await sanityClient.fetch(query);
  return partners;
};

export const fetchColabs = async () => {
  const query = '*[_type == "colab"]'; // Assuming "colab" is the type you've set in Sanity for collaborations
  const colabs = await sanityClient.fetch(query);
  return colabs;
};

export const imageUrlBuilder = (logoObject) => {
  if (logoObject && logoObject._type === 'image' && logoObject.asset && logoObject.asset._ref) {
    const imageFileParts = logoObject.asset._ref.replace("image-", "").split("-");
    const imageFileRef = imageFileParts.slice(0, imageFileParts.length - 1).join("-");
    const constructedUrl = `https://cdn.sanity.io/images/3setjl72/production/${imageFileRef}.${logoObject.asset.extension || "png"}`;

    console.log("Constructed Image URL:", constructedUrl); // Log the constructed URL

    return constructedUrl;
  }
  return ''; // Return a default/fallback image URL or an empty string here if the asset is not valid.
};

export const fetchConsultants = async () => {
  const query = '*[_type == "consultant"] | order(_createdAt asc)';
  const consultants = await sanityClient.fetch(query);
  return consultants;
};
