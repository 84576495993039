// firebase.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDiB3N7pSUJJcgUCAIaL8Cxa-etXPyro0g",
  authDomain: "rebolver-tech.firebaseapp.com",
  projectId: "rebolver-tech",
  storageBucket: "rebolver-tech.appspot.com",
  messagingSenderId: "1071247754580",
  appId: "1:1071247754580:web:4580e2c2426f3d630834c1",
  measurementId: "G-MVQ1Z6FQ2K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, app };
