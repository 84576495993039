import React, { useState, useEffect } from 'react';
import '../styles/header.css';
import { Link, useLocation } from 'react-router-dom';
import Form from './Form';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faGlobe, faShoppingCart, faLink, faRobot, faUsers, faBriefcase, faCode } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from './authContext';

library.add(faGlobe, faShoppingCart, faLink, faRobot, faUsers, faBriefcase, faCode);

const Header = () => {
    const { logout, currentUser } = useAuth();
    const [isScrolled, setIsScrolled] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [language, setLanguage] = useState('English');
    const { t, i18n: i18nInstance } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 10) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const toggleMenu = (event) => {
        event.stopPropagation();
        setMenuOpen(!menuOpen);
        const header = document.querySelector('.header');
        const hamburgerMenu = document.querySelector('.hamburger-menu');
        if (!menuOpen) {
            header.style.backgroundColor = '#00000087';
            header.style.backdropFilter = 'blur(7px)';
            hamburgerMenu.classList.add('open');
        } else {
            header.style.backgroundColor = '';
            header.style.backdropFilter = '';
            hamburgerMenu.classList.remove('open');
        }
    };

    const closeMenu = (event) => {
        if (event.target.nodeName !== 'SELECT' && event.target.nodeName !== 'OPTION') {
            setMenuOpen(false);
            const header = document.querySelector('.header');
            const hamburgerMenu = document.querySelector('.hamburger-menu');
            header.style.backgroundColor = '';
            header.style.backdropFilter = '';
            hamburgerMenu.classList.remove('open');
        }
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value === 'English' ? 'en' : 'es';
        setLanguage(event.target.value);
        i18nInstance.changeLanguage(selectedLanguage);
        localStorage.setItem('language', selectedLanguage);
    };

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language') || 'en';
        i18nInstance.changeLanguage(savedLanguage);
        setLanguage(savedLanguage === 'en' ? 'English' : 'Spanish');
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('click', closeMenu);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('click', closeMenu);
        };
    }, [i18nInstance]);

    const navigation = (
        <ul>
            <li>
                <Link to="/services" onClick={closeMenu}>{t('Services')}</Link>
                <ul className="dropdown">
                    <li><a href="/services/ecommerce#web-design" onClick={closeMenu}><FontAwesomeIcon icon="code" /> {t('Web Design')}</a></li>
                    <li><a href="/services/ecommerce#ecommerce" onClick={closeMenu}><FontAwesomeIcon icon="shopping-cart" /> {t('eCommerce')}</a></li>
                    <li><a href="/services#web3" onClick={closeMenu}><FontAwesomeIcon icon="link" /> {t('Web 3 (Blockchain)')}</a></li>
                    <li><a href="/services#ai" onClick={closeMenu}><FontAwesomeIcon icon="robot" /> {t('Artificial Intelligence')}</a></li>
                </ul>
            </li>
            <li>
                <Link to="/consultants" onClick={closeMenu}>{t('Our Team')}</Link>
                <ul className="dropdown">
                    <li><a href="/consultants#consultants" onClick={closeMenu}><FontAwesomeIcon icon="users" /> {t('Consultants')}</a></li>
                    <li><a href="/consultants#colabs" onClick={closeMenu}><FontAwesomeIcon icon="briefcase" /> {t('Our Work')}</a></li>
                </ul>
            </li>
            <li><Link to="/innovation-zone" onClick={closeMenu}>{t('Innovation Zone')}</Link></li>
            <li className='butCta'>
                <button style={{ padding: '10px 20px', background: '#1daa94', border: 'none', color: '#fff', borderRadius: '6px', fontWeight: 'bold' }} onClick={toggleModal}>{t('Contact')}</button>
            </li>
            <li style={{ padding: '10px', flexDirection: 'row', backgroundColor: '#00000087', backdropFilter: 'blur(7px)' }}>
                <FontAwesomeIcon icon={['fas', 'globe']} />
                <select value={language} onChange={handleLanguageChange} style={{ backgroundColor: 'transparent', color: 'white', fontWeight: 'bold', border: 'none', }}>
                    <option value="English">English</option>
                    <option value="Spanish">Español</option>
                </select>
            </li>
            {currentUser && (
                <li className='butCta'>
                    <button 
                        style={{ padding: '10px 20px', background: '#f44336', border: 'none', color: '#fff', borderRadius: '6px', fontWeight: 'bold' }} 
                        onClick={logout}
                    >
                        Logout
                    </button>
                </li>
            )}
        </ul>
    );

    return (
        <header className={isScrolled ? 'header scrolled' : 'header'}>
            <div className="logo">
                <Link to="/">{/* Link to home page */}
                    <img src="/logorebW.png" alt="Logo" width="71" height="50" />
                </Link>
            </div>
            <div className="desktop">
                <nav className="navigation">
                    {navigation}
                </nav>
            </div>
            <div className="mobile">
                <button className="hamburger-menu" onClick={(event) => toggleMenu(event)} aria-label="Open menu">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </button>
                <nav className={`navigation ${menuOpen ? 'open' : ''}`}>
                    {navigation}
                </nav>
            </div>
            {
                isModalOpen && (
                    <div className="modal">
                        <div className="modal-content">
                            <button onClick={toggleModal} className="close-button">X</button>
                            <Form />
                        </div>
                    </div>
                )
            }
        </header>
    );
};

export default Header;
