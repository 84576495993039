import React from 'react';
import { imageUrlBuilder } from '../utils/fetchData'

const PartnersCarousel = ({ partnersData, headerText }) => {
    console.log("Rendering with data:", partnersData);
    return (
        <section style={{ maxWidth: '90%', margin: '0 auto' }}>
            <div className="partners-carousel" id='colabs'>
                <h3 style={{ color: 'white', fontWeight: 'lighter', textAlign: 'center' }}>
                    {headerText}
                </h3>
                <div className="carousel-wrapper">
                    <div className="carousel-content">
                        {partnersData.map((partner, index) => {
                          console.log("Logo Object:", partner.logo); // LOG
                          return (
                            <div key={index} className="carousel-item">
                                <img src={imageUrlBuilder(partner.logo)} alt={partner.name} className="logo" width={150} height={100} />
                                <div className="description">{partner.description}</div>
                            </div>
                          );
                        })}
                        {/* Repeat the content to achieve a seamless loop */}
                        {partnersData.map((partner, index) => (
                            <div key={index} className="carousel-item">
                                <img src={imageUrlBuilder(partner.logo)} alt={partner.name} className="logo" width={150} height={100} />
                                <div className="description">{partner.description}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PartnersCarousel;
